import Service from '../Service'
import { LandingTysonInstance } from './InstanceTyson'

 /**
 * @typedef {Object} PldData
 * @property {String} creditApplicationId
 * @property {Boolean} publicPosition
 * @property {Boolean} directRelationship
 */

/**
 * @class
 * @param  {String} creditApplicationId
 */

export class PldService extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
    }

/**
 * @param  {String} landingApplicationId
 */
setValidation(publicPosition, directRelationship, descriptionCivilServant, descriptionRelativeToPublicOfficial) {
    this._publicPosition = !!publicPosition
    this._directRelationship = !!directRelationship
    this._descriptionCivilServant = descriptionCivilServant
    this._descriptionRelativeToPublicOfficial = descriptionRelativeToPublicOfficial
}

    /**
   * @returns {PldData}
   */
    get data () {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            publicPosition: this._publicPosition,
            directRelationship: this._directRelationship,
            descriptionCivilServant: this._descriptionCivilServant,
            descriptionRelativeToPublicOfficial: this._descriptionRelativeToPublicOfficial
        }
    }
    
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
        send () {
            const self = this
            return this._requestWrapper(LandingTysonInstance({
                method: 'PUT',
                url: '/v1/kyc/peps',
                data: self.data
            }))
        }  
}