import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
    CardElevation,
    TitleIcon,
    MaterialInput
} from '@findep/mf-landings-core'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import HowToRegIcon from '@material-ui/icons/HowToReg';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwitchBuroComponent from '../elements/SwitchBuroComponent'
import { CataloguesService } from '../../services/LandingV4'
import { navigate } from "../../helpers/queryNavigate"
import { PldService } from '../../services/LandingV4/pld';
import NameCompany from '../../helpers/NameCompany'
import { ValidacionService } from '../../services/LandingV4/validacion';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
const catalogos = new CataloguesService()
class Validacion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerless: true,
            flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
            publicPosition: null,
            directRelationship: null,
            _disabled: {},
            buttonpld: true,
            openBackDrop: false,
            errorService: false,
            legalContract: '',
            titleView: 'Buró de crédito',
            legalCaption: `Una vez que Usted haya leído y este conforme con el contrato de prestación de servicios de asesoría financiera (el "Contrato de Prestación de Servicios"), deberá seleccionar la casilla que le muestra el sitio web de ${NameCompany(this.props.companyName).legalName} en adelante ${this.props.companyName} y oprimir el botón que señala que Usted está conforme y que desea celebrar el Contrato de Prestación de Servicios para contratar a ${this.props.companyName}.
      La selección de la casilla que le muestra el sitio web de ${this.props.companyName} y el que Usted oprima aceptar, es el medio a través del cual Usted manifiesta su consentimiento expreso para celebrar el Contrato de Prestación de Servicios y contratar a ${this.props.companyName}, en sustitución a su firma autógrafa, en términos de lo señalado por los artículos 1803, 1811, 1834, 1834 Bis del Código Civil Federal y los artículos 89 y 89 bis del Código de Comercio, creándose una relación jurídica, entre Usted como cliente y ${this.props.companyName} como prestador del servicio.`,
            legalCaptionDummy: "Una vez que Usted haya leído y este conforme con el contrato de prestación de servicios de asesoría financiera (el “Contrato de Prestación de Servicios”), deberá seleccionar la casilla que le muestra el sitio web de ${this.props.companyName} y oprimir el botón que señala que Usted está conforme y que desea celebrar el Contrato de Prestación de Servicios para contratar a ${this.props.companyName}. La selección de la casilla que le muestra el sitio web de ${this.props.companyName} y el que Usted oprima aceptar, es el medio a través del cual Usted manifiesta su consentimiento expreso para celebrar el Contrato de Prestación de Servicios y contratar a ${this.props.companyName}, en sustitución a su firma autógrafa, en términos de lo señalado por los artículos 1803, 1811, 1834, 1834 Bis del Código Civil Federal y los artículos 89 y 89 bis del Código de Comercio, creándose una relación jurídica, entre Usted como cliente y ${this.props.companyName} como prestador del servicio.",
            code: '',
            confirmation: '',
            switchButton: false,
            count: 70,
            isPensionados: false,
            descriptionCivilServant: "",
            descriptionRelativeToPublicOfficial: ""
        }
    }

    async componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search)
        const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId
        if (urlParams.has('creditApplicationId')) {
            id = urlParams.get('creditApplicationId')
        }
        let df;
        if (urlParams.has('headerless')) {
            df = urlParams.get('headerless')
            if (df === 'true') {
                this.setState({ headerless: true })
            } else {
                this.setState({ headerless: false })
            }
        }
        /** */
        let urlPathname = window.location.pathname
        if (urlPathname.indexOf('pensionados') >= 0) {
            catalogos.customer(this.props.pageContext.tenant, id)
                .then((response) => {
                    const {
                        isCivilServant,
                        isRelativeToPublicOfficial,
                        publicPosition,
                        directRelationship
                    } = response.data
                    this.setState({
                        ...this.state,
                        publicPosition,//: isCivilServant === undefined ? null : isCivilServant,
                        directRelationship,//: isRelativeToPublicOfficial === undefined ? null : isRelativeToPublicOfficial,
                        isPensionados: true
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        }
        /** */
        this.setState({ creditApplicationId: id })
        const customers = new ValidacionService(this.state.flujo.creditApplicationId);
        const catData = await customers.send()
        const { publicPosition, directRelationship, legalCaption, legalContract, descriptionCivilServant, descriptionRelativeToPublicOfficial } = catData.data
        this.setState({ legalCaption })
        this.setState({ legalContract })
        this.setState({ descriptionCivilServant })
        this.setState({ descriptionRelativeToPublicOfficial })
        this.setState({ publicPosition })
        this.setState({ directRelationship })
    }

    componentDidUpdate(_, prevState) {
        const { publicPosition, directRelationship, buttonpld, isPensionados } = this.state
        if (prevState.isPensionados !== isPensionados) {
            if (isPensionados === true) {
                this.setState({ isPensionados: true })
            } else {
                this.setState({ isPensionados: false })
            }
        }
        if (isPensionados) {
            if (
                publicPosition !== null &&
                directRelationship !== null &&
                ((publicPosition === "true" || publicPosition === "false") ||
                    (publicPosition === true || publicPosition === false)) &&
                ((directRelationship === "true" || directRelationship === "false") ||
                    (directRelationship === true || directRelationship === false))
            ) {
                if (prevState.buttonpld !== buttonpld) {
                    if (buttonpld === true) {
                        this.setState({ buttonpld: true })
                    }
                    if (buttonpld === false) {
                        this.setState({ buttonpld: false })
                    }
                }
            }
        }

        if (
            publicPosition !== null &&
            directRelationship !== null
        ) {
            if (prevState.buttonpld) {
                this.setState({ buttonpld: false })
            }
        }
        if (!prevState.publicPosition && publicPosition !== null) {
            if (publicPosition === true) {
                this.setState({ publicPosition: "true" })
            }
            if (publicPosition === false) {
                this.setState({ publicPosition: "false" })
            }
        }
        if (!prevState.directRelationship && directRelationship !== null) {
            if (directRelationship === true) {
                this.setState({ directRelationship: "true" })
            }
            if (directRelationship === false) {
                this.setState({ directRelationship: "false" })
            }
        }
    }

    handleChange(prop, data) {
        const state = {
            [prop]: data.value
        }
        const tmp = Object.assign({}, this.state, state)
        this.setState(tmp, () => { })
    }

    handleOnclick = (e) => {
        e && this.nextStep()
    }

    async nextStep() {
        this.setState({
            openBackDrop: true
        })
        const pld = new PldService(this.state.flujo.creditApplicationId)
        const rp = this.state.publicPosition === 'true' ? true : false
        const dr = this.state.directRelationship === 'true' ? true : false
        const { descriptionCivilServant, descriptionRelativeToPublicOfficial } = this.state
        pld.setValidation(dr, rp, descriptionCivilServant, descriptionRelativeToPublicOfficial)
        try {
            const { data } = await pld.send()
            // const {publicPosition, directRelationship} = this.state
            if (data.canContinue === true) {
                navigate(this.props.pageContext?.next?.path)
            } else {
                navigate(this.props.pageContext.errorPath)
            }
        } catch (error) {
            this.setState({
                openBackDrop: false,
                errorService: true
            })
            //El envio al server fallo
            console.error('error en buró')
        }
    }

    render() {
        const { classes } = this.props;
        const { legalContract, legalCaption, legalCaptionDummy, publicPosition, directRelationship, isPensionados, headerless } = this.state
        return (
            <div>
                <br />
                {headerless &&
                    <>
                        <Grid container>
                            <Grid container justify="center">
                                <Grid item style={{ textAlign: "center" }}>
                                    <TitleIcon title="Validación" icon={HowToRegIcon} textColor={true} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
                <br />
                <Grid container justify="center">
                    <Grid item xs={11} md={4}>
                        <Grid container justify="center">
                            <Box>
                                <CardElevation mx="2rem" >
                                    <h1>¿Te has desempeñado en puestos públicos?</h1>
                                    <p>¿Desempeñas o has desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional, como son, entre otros, jefes de estado o de gobierno, líderes políticos, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales, funcionarios o miembro importante de algún partido político?</p>
                                    <Box style={{ textAlign: "center" }}>
                                        <FormControl>
                                            <RadioGroup value={publicPosition} aria-label="gender" name="gender1" onChange={(_event, component) => this.handleChange('publicPosition', { value: _event.target.value })} row>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio id="validacion-puestos-publicos-si" />}
                                                    label="SI"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio id="validacion-puestos-publicos-no" />}
                                                    label="NO"
                                                />
                                            </RadioGroup>
                                        </FormControl>

                                        {(this.state.publicPosition === true || this.state.publicPosition === "true")
                                            ? <>
                                                <Grid container spacing={2}>
                                                    <Grid xs={2} item>

                                                    </Grid>
                                                    <Grid xs={8} item>
                                                        <MaterialInput
                                                            id="rastreo-telefono1"
                                                            type="text"
                                                            error={""}
                                                            value={this.state.descriptionCivilServant}
                                                            onError={() => handleErrorTel('tel1', 'Número invalido')}
                                                            // onChange={(event, data) => handleTelChange('descriptionCivilServant', "")}
                                                            onChange={(_event, component) => this.handleChange('descriptionCivilServant', { value: _event.target.value })}
                                                            // pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                                                            // transform={(value) => value.toUpperCase().slice(0, 10)}
                                                            accept="0-9"
                                                            errorMessage={""}
                                                            fullWidth
                                                            label="Ingresa el Cargo"
                                                            helperText="Escribe el Cargo desempeñado"
                                                            variant="outlined"
                                                            inputmode='numeric'
                                                        />
                                                    </Grid>
                                                    <Grid xs={2} item>

                                                    </Grid>
                                                </Grid>
                                            </>
                                            : null
                                        }

                                    </Box>
                                </CardElevation>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <Grid container justify="center">
                    <Grid item xs={11} md={4}>
                        <Grid container justify="center">
                            <Box>
                                <CardElevation>
                                    <h1>¿Tienes alguna relación directa con ellos?</h1>
                                    <p>¿Eres cónyuge, concubina, o concubinario, o tienes parentesco por consanguinidad o afinidad hasta el segundo grado con personas que caen en el supuesto de la pregunta anterior?</p>
                                    <Box style={{ textAlign: "center" }}>
                                        <FormControl>
                                            <RadioGroup value={directRelationship} aria-label="gender2" name="gender2" onChange={(_event, component) => this.handleChange('directRelationship', { value: _event.target.value })} row>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio id="validacion-relacion-directa-si" />}
                                                    label="SI" />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio id="validacion-relacion-directa-no" />}
                                                    label="NO" />
                                            </RadioGroup>
                                        </FormControl>

                                        {(this.state.directRelationship === true || this.state.directRelationship === "true")
                                            ? <>
                                                <Grid container spacing={2}>
                                                    <Grid xs={2} item>

                                                    </Grid>
                                                    <Grid xs={8} item>
                                                        <MaterialInput
                                                            id="rastreo-telefono1"
                                                            type="text"
                                                            error={""}
                                                            value={this.state.descriptionRelativeToPublicOfficial}
                                                            onError={() => handleErrorTel('tel1', 'Número invalido')}
                                                            // onChange={(event, data) => handleTelChange('descriptionCivilServant', "")}
                                                            onChange={(_event, component) => this.handleChange('descriptionRelativeToPublicOfficial', { value: _event.target.value })}
                                                            // pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                                                            // transform={(value) => value.toUpperCase().slice(0, 10)}
                                                            accept="0-9"
                                                            errorMessage={""}
                                                            fullWidth
                                                            label="Escribe el tipo de relación"
                                                            helperText="Relación o Parentesco"
                                                            variant="outlined"
                                                            inputmode='numeric'
                                                        />
                                                    </Grid>
                                                    <Grid xs={2} item>

                                                    </Grid>
                                                </Grid>
                                            </>
                                            : null
                                        }

                                    </Box>
                                    <br />
                                    <br />
                                </CardElevation>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <br />

                <Grid container justify="center">
                    <Grid item style={{ textAlign: "justify" }} xs={11} md={4}>
                        <SwitchBuroComponent
                            company={this.props.companyName}
                            legalContract={legalContract}
                            switchButton={!this.state.buttonpld}
                            onClick={this.handleOnclick}
                            LegalText={legalCaption ? legalCaption : legalCaptionDummy}
                            textoSwitch="Aceptación del Contrato De Prestación De Servicios de Asesoría Financiera"
                            textButton="ACEPTAR Y CONTINUAR"
                            pensionados={isPensionados}
                        />
                    </Grid>
                </Grid>

                <Backdrop open={this.state.openBackDrop} className={classes.backdrop}>
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
                <br />
                <br />
            </div>
        )
    }
}

export default withStyles(useStyles)(Validacion)
