import axios from 'axios'

let serviceUrl = ''

switch (process.env.GATSBY_ACTIVE_ENV) {
  case 'production':
    // serviceUrl = 'https://bff-origination-service.tysonprod.com/v1/'
    serviceUrl = '/'
    break
  case 'qa':
  case 'staging':
  case 'test':
    serviceUrl = '/'
    // serviceUrl = 'https://bff-origination-service.tysonbeta.com/v1/'
    break
  default:
    serviceUrl = '/'
}

const config = {
  headers: {
    'X-App-Trace-ID': process.env.KEY_BFF_ORIGINATION_SERVICE,
  }
}

export const LandingTysonInstance = axios.create({
  baseURL: serviceUrl,
  timeout: 90000,
  headers: config.headers
})
